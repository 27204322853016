<template>
  <div class="web-tracking" :style="{ 'font-family': myStyles.font, 'background-color': myStyles.background1, }">
    <div class="v-step-2-1">
      <b-card class="card-web-tracking" :class="{ 'mb-4 t-4 px-4 mx-4': !isMobile, 'mx-02 my-02': isMobile }" :style="{ 'background-color': myStyles.background2 }" v-if="!loading.total">
        <img v-if="delivery.isSpecialCompanyLogo" :src="delivery.company_logo" class="special-logo"/>
        <div class="header-tracking" :class="{ 'has-special-logo': delivery.isSpecialCompanyLogo }">
          <div v-if="!loading.total && !delivery.isSpecialCompanyLogo"><img :src="delivery.company_logo"/></div>
          <b-skeleton v-if="loading.total" :width="`${Math.random() * (40 - 10) + 10}%`"/>
          <h4 class="vertical-center text-center ml-2 v-step-3-1" v-if="!loading.total" :style="{ 'color': myStyles.principal }"> {{ delivery.tracking_number ? `Seguimiento ${ type === 'delivery' ? 'envío' : 'devolución' }  Nº ${delivery.tracking_number}` : 'No informado' }}</h4>
          <b-skeleton v-if="loading.total" :width="`${Math.random() * (40 - 10) + 10}%`"/>
        </div>
        <timeline :timeline.sync="timeline" icon="TruckIcon" type="lineal" :styleColor="myStyles.tracking_color"></timeline>
        <div class="row" v-if="delivery.tracking_number">
          <div class="col-12 col-md-6 mt-4 mb-1" >
            <h4 :style="{ 'color': myStyles.principal }">{{$t("Historial de estados")}}</h4>
            <hr>
            <div v-if="!loading.total">
              <div v-for="(status, index) in statuses" :key="index" class="row">
                <div class="col-6" :style="{ 'color': myStyles.status_color }">{{status.name}}</div>
                <div class="col-6" :style="{ 'color': myStyles.status_color }">
                  <span class="push-right status-date" v-if="!!status.date">
                    <feather-icon icon="CalendarIcon" class="mr-1"/>
                    <span class="text">{{ $options.filters.dbDateUTCToGMTlocal(status.date.replace(' ', 'T'), 'DD/MM/YYYY HH:mm')}}</span>
                  </span>
                </div>
                <div class="col-12" :style="{ 'color': myStyles.substatus_color }">
                  <div v-for="(substatus, index2) in status.history" :key="index2" class="row ml-1">
                    <div class="col-6 v-step-6-1">
                      <small>{{substatus.name}}</small> <feather-icon v-if="substatus.trouble" icon="AlertTriangleIcon" class="text-danger"/>
                    </div>
                    <div class="col-6">
                      <span class="push-right status-date" v-if="!!substatus.date">
                        <small class="text">{{ $options.filters.dbDateUTCToGMTlocal(status.date.replace(' ', 'T'), 'DD/MM/YYYY HH:mm')}}</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="loading.total">
              <b-skeleton class="pb-05" :width="`${Math.random() * (60 - 30) + 30}%`"/>
              <b-skeleton class="pb-05" :width="`${Math.random() * (60 - 30) + 30}%`"/>
              <b-skeleton class="pb-05" :width="`${Math.random() * (60 - 30) + 30}%`"/>
              <b-skeleton class="pb-05" :width="`${Math.random() * (60 - 30) + 30}%`"/>
            </div>
          </div>
          <div class="d-sm-none d-md-block ml-3"></div>
          <div class="col-12 col-md-5 mt-4 mb-1">
            <div class="v-step-0-1">
              <h4 :style="{ 'color': myStyles.principal }">{{ type === 'delivery' ? $t("Datos del envío") : $t("Datos del devolución")}}</h4>
              <hr>
              <div v-if="!loading.total">
                <div v-for="(info, index) in deliveryData" :class="info.class" :key="`deliveryData_${index}`">
                  <span v-if="info.title" :class="info.classTitle" :style="info.colorTitle">{{ info.title }}</span>
                  <span v-if="info.content" :class="info.classContent" :style="info.colorContent">{{ info.content }}</span>
                </div>
              </div>
              <div v-if="loading.total">
                <b-skeleton class="pb-05" :width="`${Math.random() * (60 - 30) + 30}%`"/>
                <b-skeleton class="pb-05" :width="`${Math.random() * (60 - 30) + 30}%`"/>
                <b-skeleton class="pb-05" :width="`${Math.random() * (60 - 30) + 30}%`"/>
                <b-skeleton class="pb-05" :width="`${Math.random() * (60 - 30) + 30}%`"/>
                <b-skeleton class="mt-3" :width="`${Math.random() * (100 - 80) + 80}%`"/>
              </div>
            </div>
            <div class= "mt-2" v-if="isProof">
              <b-button variant="light" @click="proofOfDelivery">
                <feather-icon
                icon="FileTextIcon"/>
                {{$t("Ver comprobante de entrega")}}
              </b-button>
            </div>
          </div>
        </div>
        <div class="row" v-if="!delivery.tracking_number">
          <div class="col-12 text-center my-5">
            <h3>{{$t("Estamos preparando tu pedido, intenta más tarde.")}}</h3>
          </div>
        </div>
      </b-card>
    </div>
    <div class="push-right v-step-1-1" style="height: 3rem; width: 100%"></div>
    <widget-modal id="widgetModal" :widget="delivery.carrier_widget" :showForm="showIframeForm"
    ></widget-modal>
    <proof-modal id="proofModal" :proof="delivery.proof"
    ></proof-modal>
    <!--
    ** Ejemplo para llamar un iframe por medio de un form.
    ** Html
    <form id="myForm" ref="myForm" action="direccion_url" target="myFrame" method="post"></form>
    <iframe id="myFrame"></iframe>
    ** En un metodo con js llamar al form
    ** JS
    this.$refs.myform.submit()
    -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import logo from '@/assets/images/logo/enviame_logo.svg'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import WidgetModal from './WidgetModal.vue'
import ProofModal from './ProofModal.vue'
import deliverySample from '@/store/web-tracking/delivery-sample'

export default {
  components: { FeatherIcon, WidgetModal, ProofModal },
  props: ['styles'],
  data () {
    return {
      logo,
      timeline: [
        { id: 'CREATED', active: false, title: 'Orden en preparación', date: ''},
        { id: 'IN_ORIGIN', active: false, title: 'Retirado por operador', date: ''},
        { id: 'DISPATCHED', active: false, title: 'En reparto', date: ''},
        { id: 'DELIVERED_PUDO', active: false, title: 'Disponible para retiro', date: '', onlyOnTrue: true},
        { id: 'DELIVERED', active: false, title: 'Entregado', date: ''}
      ],
      statuses: [],
      delivery: {},
      myStyles: {},
      company_id: null,
      delivery_id: null,
      type: 'delivery',
      ticketHtml: null,
      loading: {
        personalization: true,
        delivery: true,
        total: true
      },
      showIframeForm: false,
      groups: {
        CREATED : {
          status: ['CREATED', 'PENDING', 'Orden en preparación']
        },
        IN_ORIGIN : {
          status: ['*', 'IN_ORIGIN', 'Retirado por operador']
        },
        DISPATCHED : {
          status: ['DISPATCHED', 'PARTIALLY_DELIVERED', 'En reparto', 'RETURNED']
        },
        DELIVERED_PUDO : {
          status: ['DELIVERED_PUDO', 'Disponible para retiro']
        },
        DELIVERED : {
          status: ['DELIVERED_DOM', 'PICKED_UP', 'Entregado', 'Devolución Entregada']
        }
      },
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapGetters({
      deliveryWebTracking: 'getDeliveryWebTracking' || 'getReturnWebTracking'
    }),
    isMobile() {
      return this.windowWidth <= 560
    },
    deliveryData () {
      const commonColors = { colorTitle: { 'color': this.myStyles.data1_color }, colorContent: { 'color': this.myStyles.data2_color }}
      const deliveryData = [
        { title: this.$t('Distribuido por'), content: this.delivery.carrier, class: 'pb-05', ...commonColors },
        { title: this.type === 'delivery' ? this.$t('N° de envío') : this.$t('N° de devolución '), content: this.delivery.tracking_number, class: 'pb-05', ...commonColors },
        { title: this.$t('N° de orden'), content: this.delivery.identifier, class: 'pb-05', ...commonColors }
      ]
      if (this.delivery.deadline_date && !!this.delivery?.tracking_show_deadline) {
        deliveryData.push({
          title: this.$t('Fecha estimada entrega'),
          content: this.$options.filters.dbDateToFormat((this.delivery?.deadline_date).replace(' ', 'T'), 'D'),
          classTitle: 'v-step-7-1',
          classContent: 'v-step-8-1'
        })
      }
      // if (this.delivery.url_live_tracking) {
      //   deliveryData.push({
      //     title: this.$t('Seguimiento en linea'),
      //     content: this.$createElement('a', {
      //       attrs: {
      //         href: this.delivery.url_live_tracking,
      //         target: '_blank'
      //       }
      //     }, this.$t('Haz clic aquí')),
      //     classTitle: 'v-step-7-1',
      //     classContent: 'v-step-8-1'
      //   })
      // }
      if (this.delivery.pudo) {
        deliveryData.push({ title: this.$t('Punto de retiro:'), content: this.delivery.pudo.name, class: 'pb-05', ...commonColors})
        const obj = { 
          title: this.$t('Dirección de retiro:'),
          content: `${this.delivery.pudo.address}, ${this.delivery.pudo.place}`, class: 'pb-05',
          ...commonColors
        }
        if (this.delivery.pudo.level1) obj.content += `, ${this.delivery.pudo.level1}`
        deliveryData.push(obj)
      }
      if (this.delivery?.tracking_web_message !== '') {
        deliveryData.push({ title: this.delivery?.tracking_web_message, class: 'pt-3 keep-format' })
      } else {
        deliveryData.push({ 
          title: this.$t(`Si necesitas información acerca de tu ${this.type === 'delivery' ? 'envío' : 'devolución'}, comunícate directo con `), 
          content: this.delivery.company_name, 
          class: 'pt-3',
          colorTitle: { 'color': this.myStyles.data1_color },
          colorContent: { 'color': this.myStyles.data1_color, 'font-weight': 'bold' }
        })
      }
      return deliveryData
      
    },
    isProof() {
      if (this.type === 'return') return false
      if ((this.delivery?.carrier_url || this.delivery?.carrier_widget || this.delivery?.proof) && this.delivery?.status_list[0].name === 'Entregado') return true 
      return false
    }
  },
  watch: {
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
      },
      deep: true
    },
    styles: {
      handler () {
        this.setMyStyles(this.styles)
      },
      deep: true
    },
    deliveryWebTracking () {
      this.delivery = {
        ...this.deliveryWebTracking,
        tracking_number: this.deliveryWebTracking?.delivery?.tracking_number,
        identifier: this.deliveryWebTracking?.delivery?.imported_id,
        deadlinedeadline_date_at: this.deliveryWebTracking?.tracking_show_deadline ? this.deliveryWebTracking?.deadline_date : null
        // carrier_widget: null
      }
      this.checkSpecialCaseCompanyLogo(this.delivery.company_logo)
      this.statuses = this.deliveryWebTracking.status_list//.sort((a, b) => a.order > b.order ? 1 : -1)
      if (this.delivery.tracking_number) {
        this.statuses.map(status => {
          let index = -1
          index = this.getIndexStatus(status)
          if (index !== -1) {
            if (status.is_current === true) {
              this.timeline[index].active = true
            }
            // this.checkPreviousTime(index)
            if (!!status.date) {
              // if ((i === 0) && status.is_current !== true) {
              //   this.timeline[index].date = ''
              // } else {
              this.timeline[index].date = this.$options.filters.dbDateUTCToGMTlocal(status.date.replace(' ', 'T'), 'DD/MM/YYYY HH:mm')
              // }
            }
          }
          const withTrouble = status.history.find(h => h.trouble !== 0)
          if (withTrouble?.name) {
            if (index !== -1) {
              this.timeline[index].icon = 'AlertTriangleIcon'
              this.timeline[index].iconClass = 'text-danger'
              this.timeline[index].tooltip = withTrouble.name
            }
          }
        })
        this.timeline = this.timeline.filter(el => el.active || !el.onlyOnTrue)
      }
      this.fixNotActivesStatusTimeline()
      this.loading.delivery = false
    }
  },
  mounted() {
    this.type = this.$route.params?.type
    this.delivery_id = this.$route.params?.delivery
    this.company_id = this.$route.params?.company
    if (!this.company_id) {
      this.setMyStyles(this.styles)
      this.loading.personalization = false
    } else {
      this.getPersonalization(this.company_id)
    }
    if (!this.delivery_id) {
      this.setDefaultData()
      this.loading.delivery = false
    } else if (this.type === 'return') {
      this.getReturn(this.delivery_id, this.company_id)
    } else {
      this.getDelivery(this.delivery_id, this.company_id)
    }
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {
    fixNotActivesStatusTimeline () {
      const indexLastActive = this.timeline.map(el => el.active).lastIndexOf(true)
      if (indexLastActive !== -1) {
        this.timeline = this.timeline.map((el, index) => {
          if (index <= indexLastActive) {
            el.active = true
          }
          return el
        })
      }
    },
    checkSpecialCaseCompanyLogo (url) {
      this.getMeta(url, (width, height) => {
        this.delivery.isSpecialCompanyLogo = (width / height) > 8
      })
    },
    getMeta (url, callback) {
      const img = new Image()
      img.onload = function() { callback(this.width, this.height) }
      img.src = url
    },
    getIndexStatus (status) {
      let index = -1
      if (status.history && !!status.history.length) {
        index = this.getIndexTimelineHistoryGroup(status)
      }
      if (index === -1) {
        index = this.getIndexTimelineGroup(status)
      }
      return index
    },
    checkPreviousTime (index) {
      index--
      if (this.timeline[index] && !this.timeline[index].active && this.timeline[index].onlyOnTrue) {
        // this.checkPreviousTime(index)
        index--
      }
      if (this.timeline[index] && !this.timeline[index].active) {
        this.timeline[index].active = true
        this.checkPreviousTime(index)
      }
    },
    getIndexTimelineHistoryGroup (status) {
      return this.timeline.findIndex(tl => {
        return !!status.history.find(h => {
          return this.groups[tl.id].status.includes(h.code)
        })
      })
    },
    getIndexTimelineGroup (status) {
      return this.timeline.findIndex(tl => {
        return this.groups[tl.id].status.includes(status.name)
      })
    },
    setDefaultData () {
      this.delivery = {
        ...deliverySample.data,
        tracking_number: deliverySample.data?.delivery?.tracking_number,
        identifier: deliverySample.data?.delivery?.imported_id,
        deadlinedeadline_date_at: deliverySample.data?.tracking_show_deadline ? deliverySample.data?.deadline_date : null
        // carrier_widget: null
      } 
      this.checkSpecialCaseCompanyLogo(this.delivery.company_logo)
      this.statuses = deliverySample.data.status_list //.sort((a, b) => a.order > b.order ? 1 : -1)
      if (this.delivery.tracking_number) {
        this.statuses.map(status => {
          let index = -1
          index = this.getIndexStatus(status)
          if (index !== -1) {
            if (status.is_current === true) {
              this.timeline[index].active = true
            }
            // this.checkPreviousTime(index)
            if (!!status.date) {
              // if ((i === 0) && status.is_current !== true) {
              //   this.timeline[index].date = ''
              // } else {
              this.timeline[index].date = this.$options.filters.dbDateUTCToGMTlocal(status.date.replace(' ', 'T'), 'DD/MM/YYYY HH:mm')
              // }
            }
          }
          const withTrouble = status.history.find(h => h.trouble !== 0)
          if (withTrouble?.name) {
            if (index !== -1) {
              this.timeline[index].icon = 'AlertTriangleIcon'
              this.timeline[index].iconClass = 'text-danger'
              this.timeline[index].tooltip = withTrouble.name
            }
          }
        })
        this.timeline = this.timeline.filter(el => el.active || !el.onlyOnTrue)
      }
      this.fixNotActivesStatusTimeline()
      this.loading.delivery = false
    },
    getPersonalization (id) {
      this.$root.$data.db.collection('webTracking').doc(id)
        .onSnapshot(resp => {
          this.setMyStyles(resp.data())
          this.loading.personalization = false
        })
    },
    getDelivery (id, shipper_id) {
      const msg = 'tracking-delivery'
      // if (['aacolombia', '2563'].includes(shipper_id)) msg = 'tracking-delivery-2563'
      this.$store.dispatch('fetchService', { name: 'getDeliveryWebTracking', params: {id, shipper_id}, msg})
    },
    getReturn (id, shipper_id) {
      const msg = 'tracking-delivery'
      this.$store.dispatch('fetchService', { name: 'getReturnWebTracking', params: {id, shipper_id}, msg})
    },
    setMyStyles (styles) {
      this.myStyles = {
        ...styles,
        font: styles?.font?.id || styles?.font || '"Montserrat", Helvetica, Arial, serif'
      }
      this.timeline = this.timeline.map(el => {
        el.colorTitle = styles?.status_color
        el.colorSubtitle = styles?.substatus_color
        return el
      })
    },
    proofOfDelivery() {
      if (this.delivery?.carrier_url) window.open(this.delivery?.carrier_url, '_black')
      if (this.delivery?.carrier_widget?.method === 'get') {
        document.getElementById('myFrame').src = this.delivery.carrier_widget.url
        this.$bvModal.show('widgetModal')
      } else if (this.delivery?.carrier_widget?.method === 'post') {
        this.showIframeForm = true
        this.$bvModal.show('widgetModal')
      }
      if (this.delivery?.proof) this.$bvModal.show('proofModal')
    }
  }
}
</script>
<style lang="scss">
  #loading-bg {
    opacity: 1;
    transition: opacity 0.5s ease;
    position: absolute;
    top: 0;
    &.invisible{
      opacity: 0 !important;
    }
  }
  .web-tracking {
    position: relative;
    display: flow-root;
    padding-bottom: 2.5rem;
    min-height: 100vh;
  }
  .header-tracking:not(.has-special-logo) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img{
      max-width: 200px;
      max-height: 100px;
    }
  }
  .header-tracking.has-special-logo{
    display: flex;
    flex-direction: row;
    justify-content: end;
    > h4 {
      margin-top: 1rem;
    }
  }
  .special-logo{
    max-width: 100%;
    max-height: 75px;
  }
  .status-date{
    // display: flex;
    // flex-direction: row;
    // width: 100%;
    // justify-content: space-around;
    padding-bottom: 0.75rem;
  }
  .keep-format{
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
</style>