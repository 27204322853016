const deliverySample = {
  data: {
    delivery: {
      imported_id: 'PL-27333',
      tracking_number: 'PL-27333',
      status: 'DELIVERED_DOM'
    },
    deadline_date: null,
    carrier: 'BEETRACK',
    carrier_url: '',
    company_name: 'Playa Fit Teas',
    company_logo:
			'https://storage.googleapis.com/easypoint-images/companies-logos/1732_phpuAMjQ1.png?GoogleAccessId=1048420291261-compute%40developer.gserviceaccount.com&Expires=1679074297&Signature=NpOitxDDD9kqBcN54dGRAs1DH2x7pbuc2shMzicRAQsUYDhU0DlYsdCuBQ2p5ZCPjq9%2FvOERZNkwu534A6ZWjFFKGjeNjNt2DPqH%2FtdsYCPvHHvwgaFfz5kaNz5LmlrlW19tg5W5akLGZ408SRBoWkXr%2BRIn6MmLv9FRbilRZLKiVlKyDn832aYzcO3vqxhSjCYKxDxiH%2B9afjM2Jv6BIAu4BKoJsTB3bynsJiQhxiMygSH9AMMQxwbXHwUJBFUth0HqNNlgJQiz5mekNmNn8EY9Fv6bv4bCAmOykkgsbgNB0qPRp6ATVL39fvCkB60vxrr0rcYFvf6E6g0TpdzLvw%3D%3D',
    tracking_web_message:
			'whatsapp: +56 9 5672 9719 (solo chat) - email: info@playafitteas.com - tambi\u00e9n por mensaje directo en FB o IG',
    tracking_show_deadline: true,
    tracking_show_dates: true,
    status_list: [
      {
        name: 'Entregado',
        date: '2022-09-05 15:07:34',
        comment: 'Entrega exitosa',
        order: 4,
        is_current: true,
        history: []
      },
      {
        name: 'En reparto',
        date: '2022-09-05 13:38:39',
        comment: null,
        order: 2,
        is_current: false,
        history: []
      },
      {
        name: 'Retirado por operador',
        date: '2022-09-05 11:11:52',
        comment: null,
        order: 1,
        is_current: false,
        history: [
          {
            id: 6,
            code: 'IN_ORIGIN',
            name: 'En manos del operador BEETRACK',
            trouble: 0,
            comment: 'Asignado en veh\u00edculo',
            date: '2022-09-05 11:11:52'
          }
        ]
      },
      {
        name: 'Orden en preparaci\u00f3n',
        date: '2022-09-05 09:53:09',
        comment: null,
        order: 0,
        is_current: false,
        history: [
          {
            id: 5,
            code: 'CREATED',
            name: 'Orden en preparaci\u00f3n',
            trouble: 0,
            comment: 'Ya creamos tu env\u00edo en: BEETRACK ',
            date: '2022-09-05 09:53:09'
          },
          {
            id: 1,
            code: 'PENDING',
            name: 'Creado',
            trouble: 0,
            comment: 'Envio creado por shopify',
            date: '2022-09-05 09:53:06'
          }
        ]
      }
    ],
    carrier_widget: {
      url: 'https://btr.ac/eeb479c3fdb65a05230d4bcf',
      method: 'get'
    },
    proof: null
  }
}
export default deliverySample
