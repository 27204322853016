<template>
  <b-modal :static="true"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Etiqueta generada')"
    :ok-title="$t('Cerrar')"
    size="lg"
    centered modal-class="dialog-1200 custom-dialog"
    ok-variant="outline-secondary"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    no-footer
    v-if="widget"
  >
      <b-embed
        type="iframe"
        aspect="16by9"
        allowfullscreen
        name="myFrame"
        id="myFrame"
      >
    </b-embed> 
    <div v-if="widget.method === 'post'">
      <form id="myForm" ref="myForm" target="myFrame" hidden method="post"></form>
    </div>
  </b-modal>
</template>
<script>
import { BEmbed } from 'bootstrap-vue'
export default {
  components: { BEmbed },
  name: 'widget-modal',
  inheritAttrs: false,
  props: {
    widget: { type: Object, default: undefined },
    showForm: { type: Boolean }
  },
  watch: {
    showForm() {
      if (this.showForm) this.witgetPost()
    }
  },
  mounted () {
    if (this.showForm) this.witgetPost()
  },
  methods: {
    witgetPost() {
      document.getElementById('myForm').action = this.widget.url
      setTimeout(() => document.getElementById('myForm').submit(), 1000)
    }
  }
}
</script>
